import SAMPLE_APP from '../../static/images/sampleApp.svg';
import DEMO from '../../static/images/DemoImage.svg';

export const DemoAppsItems = [
  {
    title: 'Credit Score Sample App',
    image: SAMPLE_APP,
    description:
      'See Skyflow’s end-to-end tokenization solution in action in this sample card issuing app.',
    link: 'https://creditcard-demo.skyflow.app/',
  },
  {
    title: 'Tokenization Demo',
    image: DEMO,
    description:
      'See how Skyflow can reduce your business’s PCI compliance scope using tokenization.',
    link: 'https://tokenization-demo.skyflow.app/',
  },
  {
    title: 'Governance Demo',
    image: DEMO,
    description:
      'See how Skyflow can be used to securely share sensitive data with internal and external teams.',
    link: 'https://governance-demo.skyflow.app/',
  },
  {
    title: 'Data Residency Demo',
    image: DEMO,
    description: 'See how Skyflow ensures secure data collection, residency, and governance.',
    link: 'https://data-residency-demo.skyflow.app/',
  },
  {
    title: 'PHI Tokenization Demo',
    image: DEMO,
    description:
      'See how Skyflow simplifies collecting, protecting, and using sensitive data with tokenization.',
    link: 'https://phi-tokenization-demo.skyflow.app/#/phi',
  },
  {
    title: 'PHI Governance Demo',
    image: DEMO,
    description:
      'See how Skyflow enables role-based data access with granular policies.',
    link: 'https://phi-governance-demo.skyflow.app/#/phi',
  },
  {
    title: 'Credit Card Analyst Demo',
    image: DEMO,
    description:
      'See how Skyflow enables secure PII collection with built-in field validations.',
    link: 'https://creditcard-demo.skyflow.app/#/analyst',
  },
  {
    title: 'LLM Demo',
    image: DEMO,
    description:
      'See how Skyflow enables privacy-preserving chat and file deidentification using its Data Privacy Vault.',
    link: 'https://skyflow-llm-privacy.s3.amazonaws.com/index.html',
  },
];
